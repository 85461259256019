<template>
  <!-- 产品管理 -->
  <div class="wrapper">
    <el-alert type="success" style="margin: 0 0 16px 0"
      >产品是用于定义拥有相同特性的物联网设备的逻辑单元。</el-alert
    >
    <super-table
      ref="superTable"
      :allow-create="true"
      :allow-import="true"
      :allow-export="true"
      :data="list"
      :total="total"
      :page-size="queryForm.size"
      @page-change="onPageChange"
      @size-change="onSizeChange"
    >
      <el-form
        :inline="true"
        class="query-form"
        slot="search"
        :model="queryForm"
      >
        <el-form-item prop="keyword">
          <el-input
            placeholder="关键词:名称、产商、型号"
            v-model="queryForm.keyword"
          ></el-input>
        </el-form-item>
        <el-form-item style="padding: 0; margin: 0 2px">
          <el-select
            placeholder="请选择联网方式"
            v-model="queryForm.networkWay"
            clearable
          >
            <el-option value="1" label="云对云"></el-option>
            <el-option value="2" label="NB-IoT"></el-option>
            <el-option value="3" label="WiFi"></el-option>
            <el-option value="4" label="以太网"></el-option>
            <el-option value="5" label="蜂窝网络"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            placeholder="请选择传输协议"
            v-model="queryForm.transportProtocol"
            clearable
          >
            <el-option value="1" label="HTTP"></el-option>
            <el-option value="2" label="TCP/IP"></el-option>
            <el-option value="3" label="MQTT"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            placeholder="请选择数据格式"
            v-model="queryForm.dataFormat"
            clearable
          >
            <el-option value="1" label="蝌蚪标准 - KDLink"></el-option>
            <el-option value="2" label="自定义"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>

        <!-- <el-form-item prop="keyword">
          <el-input placeholder="关键词:产品名称、生产商、型号" v-model="queryForm.keyword"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="default" @click="search">搜索</el-button>
        </el-form-item> -->
      </el-form>

      <el-form
        :model="createForm"
        :rules="createRules"
        slot="create"
        label-position="left"
        label-width="100px"
        style="margin: 0 16px"
        @submit.native.prevent
      >
        <el-row :gutter="16">
          <el-col :span="12">
            <el-form-item label="产品名称" prop="name">
              <el-input
                placeholder="请输入..."
                v-model="createForm.name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产厂家" prop="brand">
              <el-input
                placeholder="请输入..."
                v-model="createForm.brand"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="型号" prop="model">
              <el-input
                placeholder="请输入..."
                v-model="createForm.model"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品类型" prop="type.name">
              <el-select style="width: 100%" v-model="createForm.type">
                <el-option label="开关" :value="1"></el-option>
                <el-option label="摄像头" :value="2"></el-option>
                <el-option label="硬盘录像机" :value="3"></el-option>
                <el-option label="扫码器" :value="4"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联网方式" prop="networkWay.name">
              <el-select style="width: 100%" v-model="createForm.networkWay">
                <el-option label="云对云" :value="1"></el-option>
                <el-option label="NB-IoT" :value="2"></el-option>
                <el-option label="WiFi" :value="3"></el-option>
                <el-option label="以太网" :value="4"></el-option>
                <el-option label="蜂窝网络" :value="5"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="传输协议" prop="transportProtocol.name">
              <el-select
                style="width: 100%"
                v-model="createForm.transportProtocol"
              >
                <el-option label="HTTP" :value="1"></el-option>
                <el-option label="TCP/IP" :value="2"></el-option>
                <el-option label="MQTT" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Key" prop="key">
              <el-input
                placeholder="请设置..."
                v-model="createForm.key"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Secret" prop="secret">
              <el-input
                placeholder="请设置..."
                v-model="createForm.secret"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数据格式" prop="dataFormat.name">
              <el-select style="width: 100%" v-model="createForm.dataFormat">
                <el-option label="蝌蚪标准 - KDLink" :value="1"></el-option>
                <el-option label="自定义" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="自定义格式"
              v-if="createForm.dataFormat === 2"
              prop="dataContent"
            >
              <el-select
                placeholder="请选择..."
                v-model="createForm.dataContent"
              >
                <el-option
                  v-for="(item, index) in handlerList"
                  :key="index"
                  :value="item"
                  :label="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="上传图片" prop="introUrl">
              <el-upload
                list-type="picture-card"
                :headers="headers"
                action="https://api.aiot.gloryai.com/aiot-api-service/file/upload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
              >
                <img
                  v-if="createForm.introUrl"
                  :src="createForm.introUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="面板路径" prop="panelUrl">
              <el-input
                placeholder="请输入..."
                v-model="createForm.panelUrl"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="description">
              <el-input
                type="textarea"
                v-model="createForm.description"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" @click="create" native-type="submit"
            >添加</el-button
          >
        </el-form-item>
      </el-form>

      <el-table-column type="selection" align="center"></el-table-column>
      <el-table-column label="编号" prop="id" width="80"></el-table-column>
      <el-table-column label="产品名称" prop="name"></el-table-column>
      <el-table-column
        label="生产商"
        prop="brand"
        align="center"
      ></el-table-column>
      <el-table-column
        label="型号"
        prop="model"
        align="center"
      ></el-table-column>
      <el-table-column
        label="产品类型"
        prop="type.name"
        align="center"
      ></el-table-column>
      <el-table-column
        label="联网方式"
        prop="networkWay.name"
        align="center"
      ></el-table-column>
      <el-table-column
        label="传输协议"
        prop="transportProtocol.name"
        align="center"
      ></el-table-column>
      <el-table-column label="数据格式" prop="dataFormat.name" align="center">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.dataFormat.value === 1"
            >蝌蚪标准</el-tag
          >
          <el-tooltip :content="scope.row.dataContent" placement="top">
            <el-tag type="info" v-if="scope.row.dataFormat.value === 2"
              >自定义</el-tag
            >
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        prop="createdAt"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <!-- <el-button type="text" class="action" @click="firmware(scope.row)"
            >上传固件</el-button
          > -->
          <el-button type="text" class="action" @click="edit(scope.row)"
            >编辑</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-button type="text" class="action" @click="remove(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </super-table>

    <edit
      :visible.sync="showEditDialog"
      ref="editDialog"
      @on-update="search"
    ></edit>
    <!-- <firmware
      :visible.sync="showfirmwareDialog"
      ref="firmwareDialog"
      @on-update="search"
    ></firmware> -->
  </div>
</template>

<script>
import SuperTable from "@/components/SuperTable/SuperTable";
import edit from "./edit";
// import firmware from "./firmware";
export default {
  components: {
    SuperTable,
    edit,
    // firmware,
  },
  data() {
    return {
      showEditDialog: false,
      showfirmwareDialog: false,
      handlerList: [],
      queryForm: {
        keyword: "",
        page: 1,
        size: 10,
        networkWay: "",
        transportProtocol: "",
        dataFormat: "",
      },
      createForm: {
        brand: "",
        dataContent: "",
        type: "",
        dataFormat: "",
        model: "",
        name: "",
        networkWay: "",
        remark: "",
        transportProtocol: "",
        introUrl: "",
        panelUrl: "",
      },
      headers: {
        Authorization: "",
      },
      createRules: {
        name: { required: true, message: "请输入...", trigger: "blur" },
        model: { required: true, message: "请输入...", trigger: "blur" },
        type: { required: true, message: "请输入...", trigger: "blur" },
        networkWay: { required: true, message: "请输入...", trigger: "blur" },
        key: { required: true, message: "请设置...", trigger: "blur" },
        introUrl: { required: true, trigger: "blur" },
      },
      list: [],
      total: 0,
    };
  },
  async mounted() {
    await this.initHeaders();
    await this.getHandlerList();
    this.search();
  },
  methods: {
    initHeaders() {
      this.headers.Authorization =
        this.$store.getters.authToken.token_type +
        " " +
        this.$store.getters.authToken.access_token;
    },
    search() {
      this.$api.product
        .search(this.queryForm)
        .then((res) => {
          this.list = res.content;
          this.queryForm.size = res.pageable.size;
          this.total = parseInt(res.totalElements);
        })
        .catch((error) => console.log(error));
    },
    create() {
      this.$api.product
        .create(this.createForm)
        .then(() => {
          this.$refs.superTable.closeCreateDialog();
          this.search();
        })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        });
    },
    getHandlerList() {
      this.$api.handler
        .all()
        .then((res) => {
          this.handlerList = res;
        })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        });
    },
    // firmware(item) {

    //   this.$refs.firmwareDialog.firmware(item.id);
    // },
    edit(item) {
      this.$refs.editDialog.edit(item.id);
    },
    remove(item) {
      this.$confirm("确定要删除该项目吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api.product
            .destroy(item.id)
            .then(() => {
              this.search();
            })
            .catch((error) => {
              this.$message({ message: error, type: "error" });
            });
        })
        .catch(() => {});
    },
    handleAvatarSuccess(res) {
      this.createForm.introUrl = res;
    },
    onPageChange(val) {
      this.queryForm.page = val;
      this.search();
    },
    onSizeChange(val) {
      this.queryForm.size = val;
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ :focus {
  outline: 0;
}
.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
// .avatar-uploader .el-upload:hover {
//   border-color: #409eff;
// }
// .avatar-uploader-icon {
//   font-size: 28px;
//   color: #8c939d;
//   width: 96px;
//   height: 96px;
//   line-height: 96px;
//   text-align: center;
// }

.avatar {
  width: 160px;
  height: 160px;
}
</style>
