<template>
  <!-- 产品管理修改 -->
  <el-drawer
    :visible.sync="visible"
    :before-close="handleClose"
    title="修改产品信息"
    :destroy-on-close="true"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
      label-position="left"
      style="margin: 0 20px"
      v-loading="loading"
      @submit.native.prevent
    >
      <el-form-item label="产品名称" prop="name">
        <el-input placeholder="请输入..." v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="生产商" prop="brand">
        <el-input placeholder="请输入..." v-model="form.brand"></el-input>
      </el-form-item>
      <el-form-item label="型号" prop="model">
        <el-input placeholder="请输入..." v-model="form.model"></el-input>
      </el-form-item>
        <el-form-item label="产品类型" prop="type">
          <el-select style="width: 100%" v-model="form.type">
            <el-option label="开关" :value="1"></el-option>
            <el-option label="摄像头" :value="2"></el-option>
            <el-option label="硬盘录像机" :value="3"></el-option>
            <el-option label="扫码器" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传图片" prop="introUrl">
          <el-upload
            list-type="picture-card"
            :headers="headers"
            action="https://api.aiot.gloryai.com/aiot-api-service/file/upload"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
          >
            <img v-if="form.introUrl" :src="form.introUrl" class="avatar" />
            <i v-else class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      <el-form-item label="联网方式" prop="networkWay">
        <el-select placeholder="请选择..." v-model="form.networkWay">
          <el-option :value="1" label="云对云"></el-option>
          <el-option :value="2" label="NB-IoT"></el-option>
          <el-option :value="3" label="Wifi"></el-option>
          <el-option :value="4" label="以太网"></el-option>
          <el-option :value="5" label="蜂窝网络"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="传输协议" prop="transportProtocol">
        <el-select placeholder="请选择..." v-model="form.transportProtocol">
          <el-option :value="1" label="HTTP"></el-option>
          <el-option :value="2" label="TCP/IP"></el-option>
          <el-option :value="3" label="MQTT"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Key" prop="key">
        <el-input placeholder="请输入..." v-model="form.key"></el-input>
      </el-form-item>
      <el-form-item label="Secret" prop="secret">
        <el-input placeholder="请输入..." v-model="form.secret"></el-input>
      </el-form-item>
      <el-form-item label="数据格式" prop="dataFormat">
        <el-select placeholder="请选择..." v-model="form.dataFormat">
          <el-option :value="1" label="蝌蚪标准"></el-option>
          <el-option :value="2" label="自定义"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="自定义格式"
        v-if="form.dataFormat === 2"
        prop="dataContent"
      >
        <el-select placeholder="请选择..." v-model="form.dataContent">
          <el-option
            v-for="(item, index) in handlerList"
            :key="index"
            :value="item"
            :label="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="面板路径" prop="panelUrl">
        <el-input placeholder="请输入..." v-model="form.panelUrl"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" :loading="submiting"
          >提交</el-button
        >
        <el-button type="default" @click="visible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      submiting: false,
      handlerList: [],
      form: {
        id: "",
        brand: "",
        dataContent: "",
        type: "",
        dataFormat: "",
        model: "",
        name: "",
        networkWay: "",
        remark: "",
        transportProtocol: "",
        introUrl: "",
        key: "",
        secret: "",
        panelUrl: "",
      },
      headers: {
        Authorization: "",
      },
      rules: {
        name: {
          required: true,
          message: "请输入产品名称",
          trigger: "blur",
        },
        type: { required: true, message: "请输入...", trigger: "blur" },
        key: { required: true, message: "请输入...", trigger: "blur" },
        introUrl: { required: true, trigger: "blur" },
      },
    };
  },
  mounted() {
    this.initHeaders();
  },
  methods: {
    initHeaders() {
      this.headers.Authorization =
        this.$store.getters.authToken.token_type +
        " " +
        this.$store.getters.authToken.access_token;
    },
    async edit(id) {
      await this.getHandlerList();
      this.visible = true;
      this.loading = true;
      this.$api.product
        .show(id)
        .then((res) => {
          this.form["id"] = res.id;
          this.form["brand"] = res.brand;
          this.form["dataContent"] = res.dataContent;
          this.form["type"] = res.type.value;
          this.form["dataFormat"] = res.dataFormat.value;
          this.form["model"] = res.model;
          this.form["name"] = res.name;
          this.form["networkWay"] = res.networkWay.value;
          this.form["remark"] = res.remark;
          this.form["transportProtocol"] = res.transportProtocol.value;
          this.form["introUrl"] = res.introUrl;
          this.form["key"] = res.key;
          this.form["secret"] = res.secret;
        })
        .catch((error) => {
          this.$message({ messge: error, type: "danger" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getHandlerList() {
      this.$api.handler
        .all()
        .then((res) => {
          this.handlerList = res;
        })
        .catch((error) => {
          this.$message({ message: error, type: "error" });
        });
    },
    submit() {
      this.submiting = true;
      this.$api.product
        .update(this.form.id, this.form)
        .then(() => {
          this.$message({ message: "提交成功", type: "success" });
          this.visible = false;
          this.$emit("on-update");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.submiting = false;
        });
    },
    handleClose() {
      this.$emit("update:visible", false);
    },
    handleAvatarSuccess(res) {
      this.form.introUrl = res;
    },
  },
};
</script>


<style lang="scss" scoped>
/deep/ :focus {
  outline: 0;
}

.avatar {
  width: 160px;
  height: 160px;
}
</style>